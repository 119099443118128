import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Layout from "../layouts/zh";
import SEO from "../components/seo";
import Img_banner from "../images/banner-promotion.png";
import { Tabs } from "antd";
import $ from "jquery";

const { TabPane } = Tabs;

const PartnerPage = (props) => {
  const [state, setState] = useState({
    benefitDataSelected: false,
  });

  useEffect(() => {
    init();
  }, [state.benefitDataSelected]);

  const init = () => {
    $(".cta-expand-zh").off("click");
    expandData();
  };

  const onChangeTab = () => {
    setState(!state.benefitDataSelected);
  };

  const expandData = () => {
    $(".cta-expand-zh").on("click", function (e) {
      var target = $(this).data("target");

      $(target).slideToggle(400);
      $(this).closest(".copy-zh").find(".content-zh").toggleClass("active");

      if (!$(this).hasClass("active")) {
        $(this).addClass("active").html("Show Less");
      } else {
        $(this).removeClass("active").html("Show More");
      }
    });
  };

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
      @media (max-width: 800px) {
        .benefit__special{
          display: block !important
        }
      }
      .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
        margin: 0px auto
      }
      .benefit__special .table--benefit {
        display: block
      }
      .benefit__tab {
        font-weight: normal
      }
      .ant-tabs-tab {
        margin-right: -15px;
      }
      @media (max-width:480px){
        .ant-tabs-tab {
          margin-right: -3px;
        }
      }
    `,
        }}></style>
      <Layout location={props.location}>
        <SEO title="提携パートナー＆特典内容 " />

        <div id="contentWrap">
          <div id="index_content" className="admin-setting">
            <div className="benefit__special partner_content">
              <div className="container container--600">
                <h1 className="text--center">合作伙伴和福利</h1>
                <p className="text--center">
                  获取我们合作伙伴的最新促销信息，过上您最完美的生活方式。
                </p>
              </div>
              <div className="container container--1000">
                <Tabs
                  className="partner__tab"
                  defaultActiveKey="benefit-all"
                  size="large"
                  onChange={onChangeTab}>
                  <TabPane tab="阿雅娜集团" key="benefit-all">
                    <div className="table--benefit">
                      <div className="list-item">
                        <div className="row">
                          <div className="col col-5 col-sm-12">
                            <div className="img">
                              <img
                                alt=""
                                src="https://resource.ayana.com/live/rewards/page/Webp.net-resizeimage_(42).jpg"
                              />
                            </div>
                          </div>
                          <div className="col col-7 col-sm-12">
                            <div className="copy copy-zh">
                              <h3 className="title">Kawano集团</h3>
                              <div className="content content-zh">
                                <p className="desc">
                                  用阿雅娜会员优享计划积分兑换Kawano餐厅的代金券以及Kawano餐厅集团的9折优惠。以您累积的每个积分在印尼最佳餐厅中享用美食。
                                </p>
                              </div>
                              <div
                                className="container-expandable hide"
                                id="collapse-1">
                                <span>条款和条件</span>
                                <br />
                                1. 会员优惠
                                <br />
                                - 会员可在以下Kawano餐厅集团列表中享受9折优惠：
                                <br />
                                <ul className="dots">
                                  <li>雅加达阿雅娜中城广场酒店KaDo餐厅</li>
                                  <li>雅加达阿雅娜中城广场酒店本膳日式餐厅</li>
                                  <li>巴厘岛阿雅娜水疗度假酒店本膳日式餐厅</li>
                                  <li>阿雅娜科莫多度假村本膳日式餐厅</li>
                                  <li>Sakana Midplaza日式餐厅</li>
                                  <li>Sakana Mega Kuningan 日式餐厅</li>
                                  <li>Sakana Delonix Karawang 日式餐厅</li>
                                  <li>Tontoki Midplaza 日式餐厅</li>
                                  <li>Tontoki Mega Kuningan日式餐厅</li>
                                  <li>The Grill Midplaza 烧烤餐厅</li>
                                  <li>Udonku Midplaza 日式餐厅</li>
                                  <li>Aro Aroy Midplaza 日式餐厅</li>
                                  <li>Bistronomy Labuan Bajo 餐厅</li>
                                  <li>Riverside 餐厅</li>
                                </ul>
                                - 会员须登录{" "}
                                <Link to="/zh/reward">
                                  <span style={{ color: "#0000cd" }}>
                                    ayanarewards.com，
                                  </span>
                                </Link>
                                向餐厅服务员出示会员信息页面
                                <br />
                                - 此折扣无最低或最高消费条件
                                <br />
                                - 折扣不包括酒精饮料
                                <br />
                                - 此折扣不能与其它优惠同时使用
                                <br />
                                <br />
                                2. 积分兑换
                                <br />
                                -
                                会员可通过此链接使用1250积分兑换价值为30万印尼盾的Kawano集团餐厅餐饮代金券
                                <br />
                                -
                                会员需打印出纸质代金券，然后出示给餐厅服务员消费
                                <br />
                                - 会员可以用现金或刷卡支付剩余
                                <br />
                              </div>
                              <a
                                className="cta-expand cta-expand-zh btn-toggle"
                                data-target="#collapse-1">
                                展现更多
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="list-item">
                        <div className="row">
                          <div className="col col-5 col-sm-12">
                            <div className="img">
                              <img
                                alt=""
                                src="https://resource.ayana.com/live/rewards/page/Webp.net-resizeimage_(43).jpg"
                              />
                            </div>
                          </div>
                          <div className="col col-7 col-sm-12">
                            <div className="copy copy-zh">
                              <h3 className="title">Riverside高尔夫俱乐部</h3>
                              <div className="content content-zh">
                                <p className="desc">
                                  <span style={{ color: "rgb(34, 34, 34" }}>
                                    地点:&nbsp;
                                  </span>
                                  &nbsp;Bukit Golf Riverside Cimanggis, West
                                  Java
                                  <br />
                                  想在工作日或周末打免费高尔夫球？用您的积分即可实现！
                                </p>
                              </div>
                              <div
                                className="container-expandable hide"
                                id="collapse-2">
                                <span>条款和条件</span>
                                <br />
                                -
                                &rsquo;-会员可通过此链接使用2500积分兑换免费单次高尔夫球场工作日打球，或者7700积分兑换免费单次高尔夫球场周末打球
                                <br />
                                -
                                兑换项目包括果岭费，高尔夫球场服务（电动高尔夫球车和高尔夫球童），税金和保险
                                <br />
                                -
                                发送电子邮件至info@ayanarewards.com说明打球所选日期进行预订
                                <br />
                                - 周末和公共假期的预订最迟可在抵达2周前进行预订
                                <br />
                                - 会员必须在注册时出示身份证
                                <br />
                                - 会员可在Riverside餐厅享受用餐9折优惠
                                <br />
                                -
                                所有预订均不可退款且不可取消。您的积分将在兑换时扣除，并将遵循兑换政策。
                                <br />
                              </div>
                              <a
                                className="cta-expand cta-expand-zh btn-toggle"
                                data-target="#collapse-2">
                                展现更多
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="list-item">
                        <div className="row">
                          <div className="col col-5 col-sm-12">
                            <div className="img">
                              <img
                                alt=""
                                src="https://resource.ayana.com/live/rewards/page/Webp.net-resizeimage_(41).jpg"
                              />
                            </div>
                          </div>
                          <div className="col col-7 col-sm-12">
                            <div className="copy copy-zh">
                              <h3 className="title">Flower Studio花店</h3>
                              <div className="content content-zh">
                                <p className="desc">
                                  <span style={{ color: "rgb(34, 34, 34)" }}>
                                    地点:&nbsp;
                                  </span>
                                  Midplaza Building 1 Lvl Basement, Jakarta
                                  <br />
                                  消费阿雅娜会员优享计划积分购买Flower
                                  Studio花店的代金券以及花艺产品的9折优惠。用您累积的每个积分给生活添上几道亮丽的色彩。
                                </p>
                              </div>
                              <div
                                className="container-expandable hide"
                                id="collapse-3">
                                <span>条款和条件</span>
                                <br />
                                1. 会员优惠
                                <ul className="dots">
                                  <li>
                                    会员可以9折优惠享受来自Flower
                                    Studio花店的花艺产品
                                  </li>
                                  <li>
                                    会员须登录
                                    ayanarewards.com，向花店店员出示会员信息页面
                                  </li>
                                  <li>此折扣无最低或最高消费条件</li>
                                  <li>此折扣不能与其它优惠同时使用</li>
                                </ul>
                                <br />
                                2. 积分兑换
                                <ul className="dots">
                                  <li>
                                    会员可通过此链接使用积分兑换价值为30万印尼盾，50万印尼盾以及100万印尼盾的Flower
                                    Studio花店代金券
                                  </li>
                                  <li>
                                    会员需打印出纸质代金券，然后出示给Flower
                                    Studio花店店员消费
                                  </li>
                                  <li>
                                    建议会员进行消费的前一天提前预订。会员也可以使用代金券购买花店的当天现货
                                  </li>
                                  <li>会员可以用现金或刷卡支付剩余</li>
                                </ul>
                              </div>
                              <a
                                className="cta-expand cta-expand-zh btn-toggle"
                                data-target="#collapse-3">
                                展现更多
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPane>

                  {/* <TabPane tab="飞行常客计划" key="benefit-ayana">
                    <div className="table--benefit">
                      <div className="list-item">
                        <div className="row">
                          <div className="col col-5 col-sm-12">
                            <div className="img">
                              <img
                                alt=""
                                src="https://resource.ayana.com/live/rewards/page/KrisFlyer.jpg"
                              />
                            </div>
                          </div>
                          <div className="col col-7 col-sm-12">
                            <div className="copy copy-zh">
                              <h3 className="title">KrisFlyer里程</h3>
                              <div className="content content-zh">
                                <p className="desc">
                                  以2,000阿雅娜会员优享计划积分兑换成1,000KrisFlyer里程。入住天数更多，就可累积更多的里程。
                                </p>
                              </div>
                              <div
                                className="container-expandable hide"
                                id="collapse-5">
                                <span>条款和条件</span>
                                <ul className="dots">
                                  <li>
                                    会员可以2,000阿雅娜会员优享计划积分兑换成1,000KrisFlyer里程，反之则不然。
                                  </li>
                                  <li>请点击 此链接进行兑换</li>
                                  <li>
                                    会员须通过info@ayanarewards.com
                                    电子邮件信箱向阿雅娜会员优享计划提供KrisFlyer会员编号以及姓名
                                  </li>
                                  <li>兑换申请需要7个工作日完成</li>
                                </ul>
                              </div>
                              <a
                                className="cta-expand cta-expand-zh btn-toggle"
                                data-target="#collapse-5">
                                展现更多
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="list-item">
                        <div className="row">
                          <div className="col col-5 col-sm-12">
                            <div className="img">
                              <img
                                alt=""
                                src="https://resource.ayana.com/live/rewards/page/GARUDA.jpg"
                              />
                            </div>
                          </div>
                          <div className="col col-7 col-sm-12">
                            <div className="copy copy-zh">
                              <h3 className="title">Garuda里程</h3>
                              <div className="content content-zh">
                                <p className="desc">
                                  以2,000阿雅娜会员优享计划积分兑换成1,000KrisFlyer里程。用您累积的里程随时飞行到您所选择的目的地旅行。
                                </p>
                              </div>
                              <div
                                className="container-expandable hide"
                                id="collapse-6">
                                <span>条款和条件</span>
                                <ul className="dots">
                                  <li>
                                    会员可以2,000阿雅娜会员优享计划积分兑换成1,000
                                    Garuda 里程，反之则不然。
                                  </li>
                                  <li>请点击 此链接进行兑换</li>
                                  <li>
                                    会员须通过info@ayanarewards.com
                                    电子邮件信箱向阿雅娜会员优享计划提供Garuda里程会员编号以及姓名
                                  </li>
                                  <li>兑换申请需要7个工作日完成</li>
                                </ul>
                              </div>
                              <a
                                className="cta-expand cta-expand-zh btn-toggle"
                                data-target="#collapse-6">
                                展现更多
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPane> */}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default PartnerPage;
